
@import "colour.less";

body{
    font-size: 13px;
    font-family: arial, sans-serif;
    line-height: 1.5;

    h1, h2, h3, h4, h5{
        font-size: 13px;
    }
}
article{
    font-size: 13px;
    line-height: 1.5;
    p{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.link-mixin{
        color: @green;
        text-decoration: none;
        &:hover{
            color: @white;
            background-color: @green;
        }
}
.link-mixin-important{
    .link-mixin;
    color: @green !important;
    &:hover{
            color: @white !important;
            background-color: @green !important;
    }
}
a{
    text-decoration: none;
}
.highlight{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); 
    padding: 15px;
}
#page-item .search { 
    .highlight {
    
        box-shadow: none;
        padding: inherit;
        font-weight: bold;
    }
    .result-title .highlight {
        background-color: @yellow;
    }
    form{
        margin-left: 0;
        
        input, p{
            margin-left: 0;
        }
    }
}
