/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 7 Feb 2021, 12:48:34
    Author     : james
*/

.btn-primary{
    
    padding: 0.5rem 1rem;
    border: 0;
    background-color: @green;
    
    margin-top: 2rem;
    
    &:hover{
        background-color: @light-green;
    }
}
input, input:focus, input:hover, input[type='text']:focus, input[type='text']:hover,
input[type='password']:focus, input[type='password']:hover{
    box-shadow: none;
    border-color: white;
    outline: none;
}
.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, 
.btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
    background-image: none;
    background-color: #0E6D67;
    color: white;
    display: block;
}
.btn-primary:disabled{
    background-color: grey;
    color: black;
}
.btn-primary:disabled:hover{
    background-color: grey;
    color: black;
}

.btn-primary:hover{
    background-color: #48A942;
}
div.login form, div.login fieldset {
    margin-bottom: 0;
    border: 0;
    padding-bottom: 0;
    box-shadow: none;
}

div.login > form >  fieldset > div.control-group:nth-child(3) {
    margin-bottom: 0;
}

div.login ~ div ul {
    margin-left: 180px;
}

div.login ~ div > ul.nav > li > a,
div.login ~ div > ul.nav > li > a:hover {
    border: 0;
    color: black;
    background-color: inherit;
}

div.login ~ div > ul.nav > li > a:hover {
    color: #48A942;
}
.item-page input[type='password'], .item-page input[type='text'], .item-page input[type='email'],
.item-page input[type='url'], .item-page input[type='date'],
.login input[type='password'], .login input[type='text'], .item-page  input[type='file']{
    font-size: inherit;
    padding: 5px;
    border-color: white;
    border-width: 0px;
    margin: 0;
    height: auto;
    display: block;
    float: left;
    margin-bottom: 15px;
    width: 240px;
    display: block;
    color: black;
    line-height: 20px;
}
.item-page  input[type='file']{
    height: 25px;
    line-height: 14px;
}
.item-page select#cpdentryactivity{
    margin-left: 65px;
    width: 405px;
}
.item-page select{
    display: block;
    float: left;
    width: 250px;
    height: auto;
    line-height: 1.6;
    color: black;
    padding: 5px;
    border: 0;
}
.item-page select, .item-page select:focus{
    cursor: auto;
    box-shadow: none;
}
.item-page option {
    cursor: auto;
    color: black;
    line-height: 1.6;
    min-width: 220px;
}
.item-page  input.button.search{
    border: 0;
    padding: 3px 0 0 0;
    margin-left: -20px;
    display: block;
    float: right;
    position: relative;
}
.item-page form {
    padding: 15px;
    width: 550px;
    margin-left: -15px;
    display: block;
    background-color: #D6E9E1;
}
.item-page fieldset {
    margin-top: 5px;
}
.item-page label{
    float: left;
    margin-left: 5em;
    width: 550px;
    clear: left;
}
.item-page form legend {
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
    margin: 15px 65px;
    width: auto;
    color: #003D7D;
}
form#archive-search{
    margin-bottom: 20px;
}
form#archive-search select{
    width: 100px;
    margin-right: 20px;
}
form#archive-search select.input-mini{
    width: 75px;
    margin-right: 113px;
}
#jform_articletext_container, #jform_articletext_parent{
    clear: both;
    display: inline-block;
    float: none;
    width: 550px;
}
div.edit.item-page #adminForm ul.nav{
    margin-left: 0;
    border: 0;
}
div.edit.item-page #adminForm ul.nav li {
    display: none;
}
div.edit.item-page #adminForm ul.nav li:first-child{
    display: inherit;
}
input#jform_alias{
    display: none;
}