/* ------- main menu --------- */
.horiz-menu-wrapper{


    height: 110px;
    background-repeat: repeat-x;
    background-position: center top 53px;

    background-image: url('https://www.rsb.org.uk/templates/sb_main/images/lavender.png');
    &.brassica-napus {
        background-image: url('https://www.rsb.org.uk/templates/sb_main/images/brassica_napus.png');
    }
    &.grass{
        background-image: url('https://www.rsb.org.uk/templates/sb_main/images/grass.png');
    }
    @media ( max-width: .col-width(13)[@result] ){

        height: 180px;
        background-position: center bottom 0;
    }
    
    .horiz-menu{

        position: relative;
        z-index: 1; /* drop down on top of the carousel and breadcrumbs */

        .bg {

            position: absolute;
            bottom: 7px;
            width: 150px;
            height: 145px;

            background-repeat: no-repeat; 
            background-position: left bottom 0;

            @media ( min-width: .col-width(13)[@result] ){
                &.left{
                    background-image: url('../images/bg_globe_scope.png');
                    left: -150px;
                }
                &.right{
                    background-image: url('../images/bg_tree_dna_bird.png');
                    background-repeat: no-repeat;
                    right: -200px;
                }
                &.foot.left{
                    background-image: url('../images/bg_beaker.png');
                    left: -150px;
                }
                &.foot.right{
                    background-image: url('../images/bg_ant_cloud.png');
                    background-repeat: no-repeat;
                    right: -200px;
                    bottom: -7px;
                }
            }
        }

        font-weight: bold;
        font-size: 15px;

        ul.nav{

            position: relative;

            padding: 0;
            height: 60px;
            margin: 0;

            display: flex;
            justify-content: space-between; 
            align-content: flex-end;
            @media ( max-width: .col-width(13)[@result] ){
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                height: 100px;
                align-content: flex-start;
            }

            li.hidden{
                display: none;
            }

            & > li{

                /* Inside first level list item */



                display: flex;
                flex-direction: column;
                align-self: flex-end;
                @media ( max-width: .col-width(13)[@result] ){
                    align-self: flex-start;
                }

                max-width: 7em;
                margin-right: 1em; 
                &:last-child{
                    margin-right: 0;
                    margin-left: -1em;
                    a{
                        text-align: right;
                    }
                }

                a{
                    text-decoration: none;
                    display: block;
                }
                & > a{

                    color: @blue;


                    border-bottom-style: solid;
                    @media ( max-width: .col-width(13)[@result] ){
                        padding-bottom: 0px;
                        margin-bottom: 11px;
                    }
                    border-bottom-width: 7px;
                    border-bottom-color: transparent;

                    padding-bottom: 11px;


                }
                &:hover > a{

                    color: @blue;
                    border-bottom-color: @green;
                }
                &.active > a{

                    color: @blue;
                    border-bottom-color: @blue;
                }

                & > ul{

                    /* Inside second level list */

                    display: grid;

                    @media ( max-width: .col-width(13)[@result] ){
                        display: none;
                    }

                    grid-gap: @gutter;
                    grid-row-gap: 1ex;
                    grid-template-columns: 
                        auto auto auto auto auto;
                    position: absolute;
                    box-shadow: 0 3px 3px #BFBFBF;

                    width: 100%;
                    background-color: @light-green;

                    transition: opacity 0.6s ease-in-out;
                    opacity: 0;
                    visibility: hidden;

                    top: 60px;
                    left: -500%;
                    padding: 15px 500%;

                    li{
                        display: block;
                        float: left;

                        a{
                            color: black;
                            font-weight: normal;
                            font-size: 13px;
                        }

                        &:hover a, &.active:hover a {
                            color: @green;
                        }
                        &.active a {
                            color: @blue;

                        }
                    }
                }

                &:hover ul{


                    visibility: visible;

                    opacity: 0.9;

                }


            }    


        }
    }

    @media ( max-width: .col-width(13)[@result] ){

        height: 160px;
        
        .horiz-menu{

            ul.nav{
                display: grid;

                grid-gap: 0 @gutter;

                grid-template-columns: 
                    auto
                    auto
                    auto
                    auto
                    auto;

                & > li {
                    display: block;

                    max-width: initial;

                    &:last-child{
                        margin-left: 0;
                        a{
                            text-align: left;
                        }
                    }

                    a{
                        margin-bottom: 0;
                        margin-top: 5px;
                        border-bottom-color: @blue;
                        
                        &.active, &:hover{
                            border-bottom-color: @green;
                        }
                    }
                }
            }
        }
    }

    @media ( max-width: .col-width(10)[@result] ){

        height: 170px;
        
        .horiz-menu{

            ul.nav{
                grid-template-columns: 
                    auto
                    auto
                    auto
                    auto;
            }
        }
    }
    
    @media ( max-width: .col-width(8)[@result] ){

        height: 190px;
        .horiz-menu{

            ul.nav{
                grid-template-columns: 
                    auto
                    auto
                    auto;
            }
        }
    }
}

