
/*
    Created on : 25 Aug 2020, 09:56:51
    Author     : James Hamlin
*/
#page-item{

    .item-image{
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    article{

        padding: 15px;
        display: block;
        font-size: 13px;

        background-color: @grey;

        margin-top: 15px;;
        &:first-child{
            margin-top: 0;
        }

        .article-info-term{
                display: none;
        }
        dd.published, dd.created, dd.midified{
            margin-left: 0;
            color: darken(@grey,50%);
        }
        
        .page-header{

            margin-bottom: 15px;

            h1, h2, h3, h4, h5, h6, h7{
                color: @black; 
                font-size: 21px;
                line-height: 24px;
                font-weight: normal;
                margin: 0;
            }
        }

        h1, h2, h3, h4, h5, h6, h7{

            font-size: inherit;
            line-height: 17px;
            font-weight: bold;
            padding: 0;
            margin: 0 0 @padding 0;
            text-decoration: none;
            color: @blue;
            float: none;
            clear: both;
        }

        h2{
            color: black;
        }
        h3{
            font-weight: normal;
        }

        h4{
            color: @gray;
        }
        a{
            .link-mixin;
        }

        ul, ol{

            margin-left: 65px;
            padding: 0;
            list-style: disc;
            li{
                line-height: 20px;
            }
        }
        ol{
            list-style: decimal;
        }

        strong {
            font-weight: bold;
        }

        img{
            float: left;
            margin: @padding @padding @padding 0;

            &[style*="right"]{
                margin: @padding 0 @padding @padding;
            }
            &[width^="5"]{
                width: 100%;
                height: auto;
            }
        }

        figure{
            display: inline-block;
            margin: @padding 0;
            &:last-child{
                margin-bottom: 0;
            }
            img{
                margin: 0 0 @padding 0;
            }
            figcaption{
                display: block;
                margin-top: @padding;
                text-align: center;
                float: none;
                clear: both;
                font-weight: bold;
                color: @gray;
            }
        }
        
        p{
            margin: 10px 0;
            &:last-of-type{

                display: inline-block;
                margin-bottom: 0;
                img{
                    margin-bottom: 0;
                }
                figure{
                    margin-bottom: 0;
                }
            }
        }
        
    }
}
.link-mixin{

    font-weight: bold;
    color: @green;
    text-decoration: none;
    &:hover{
        color: @white;
        background-color: @green;
    }
}