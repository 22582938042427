/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 17 Aug 2020, 13:56:38
    Author     : james
*/

footer.page-footer {

    .foot-col{
        
        .span(5, 2, 10);
        .span(13/2, 2, 13);
        .span(8, 2);
        
        &.left{

        }
        &.right{

        }
        
        a{
            text-decoration: none;
            color: black;
            &:hover, &:focus{
                color: @green;
            }
        }
        
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                
                .span(5/3,3,10);
                .span(2,4,16);
                
                line-height: 20px;
                font-size: 10px;
            }
            & > li{
                margin-bottom: 0;
            }
        } 
        p.dev-name {
            display: none;
        }
    }
}