/*
    Created on : 6 Feb 2021, 16:28:22
    Author     : James Hamlin
*/
#page-item{

    .item-page, .blog, .blog-featured, .archive{


        padding: 15px;
        display: block;
        font-size: 13px;

        background-color: @grey;

        .page-header{

            margin-bottom: 15px;
            
            &:first-child{
            h1, h2, h3, h4, h5, h6, h7{
                color: @green; 
                font-size: 21px;
                line-height: 24px;
                font-weight: normal;
                margin: 0;
            }
            }
        }

        .items-row{
            border-top: 1px solid black;
                margin-bottom: 15px;
        }
        
        .items-leading, .item, #archive-items{
            .row0, .row1{
            border-top: 1px solid black;
                margin-bottom: 15px;
            }
            
            h1, h2, h3, h4, h5, h6, h7{
                color: @black; 
                font-size: 0.9rem;
                line-height: 1.4;
                font-weight: bold;
                margin: 0;
            }
            dt{
                display: none;
            }
            dd {
                padding: 0;
                margin: 0;
                color: @gray;
                *{
                    display: none;
                }
                time{
                    display: inline;
                }
            }
        }



    } 

    form{
        background-color: inherit;
        
        select{
            border: 0;
            padding: 4px 6px;
            margin-right: 1rem;
            
            &:focus, &:focus-visible{
                border: 0;
            }
        }
    }
    fieldset{
        border: 0;
    }

    a{
        .link-mixin;
    }
}

