

#div-pos-menu{

    .menu-block{
        .span(16);
        .menu-block;
    }


}
#div-pos-logo{

    //.span(3,2,3);
    
    width: .col-width( 2 )[@result];
    @media ( min-width: .col-width( 7 )[@result] ){
        width: .col-width( 3 )[@result];
    }
    float: left;
    display: block;
    margin-bottom: 0; 
    
    img{
        width: 100%;
        height: auto;
    }
}
@menuButtonWidth: 54px;

#div-top-bar{

    //background-color: yellow;
    //height: 100px;

    .right(5);

    .span(4,2,5);
    .span(7,2,10);
    .span(10,2,13);
    .span(13,2,16);

    position: relative;

    #search-block-top{
        .span(3, 1, 6);


        top: 0;
        right: @menuButtonWidth + 2px;
        @media ( min-width: .col-width( 16 )[@result] ){

            right: 0;

        }

        form{
            width: 100%;
            height: 30px;
            background-color: @green;

            padding: 5px;
            box-sizing: border-box;

            position: relative;
            input{
                box-sizing: border-box;
                width: 100%;
                height: 20px;

                padding: 4px;
                line-height: 14px;

                border: 0;
            }
            input[type="image"]{
                position: absolute;

                top: 3px;
                right: 4px;

                height: 25px;
                width: auto;
            }
        }
    }
    #menu-block-top {
        
        //.span(9, 1, 16);
        width: @menuButtonWidth;

        right: 0;
        @media ( min-width: .col-width( 16 )[@result] ){

            width: .col-width( 9 )[@result];
            right: .col-width( 3 )[@result];
        }
    }
    & > div {
        position: absolute;
        z-index: 2;
        top: 0;
    }
}