

.hm-pg-mod-menu{
    
    padding: 0;
    li{
        display: block;
        width: 100%;
        margin: 0;
            
        border-top: 3px solid white;
        
        list-style: none;
        
        &:first-child{
            border-top: 0;
        }
        &:hover{
            background-color: @light-green;
        }
        a{
            display: block;
            
            height: 37.58px;
            width: 100%;
            margin: 0;
            padding: 0 15px;
            
            font-size: 18px;
            line-height: 37.5px;
            
            text-decoration: none;
            color: black;
            
            
        }
    }
}

.home-section .home-pos {
    .moduletable  .mod-inner  ul.nav{
        .hm-pg-mod-menu;
    }
}