


.centre (@columns: @page-width, @minScreen: @page-width){
    
    .span(@columns, 1, @minScreen);
    float: none;
    margin: 0 auto;
    padding: 0;
}

.container(@columns){
    
    float: left;
    @media ( min-width: ( @columns * ( @column-width + @gutter ) ) ){
        width: ( @columns * ( @column-width + @gutter ) - @gutter );
        display: block;
    }
    
}

/*.span(@columns, @cols: 1, @fallback: 0) when not( @fallback = 0 ){
    
    
    @media ( min-width: ( @fallback * ( @column-width + @gutter ) ) - @gutter ){
        width: ( ( @fallback * ( @column-width + @gutter ) ) - @gutter );
        //overflow: hidden;
        display: block;
        box-sizing: border-box;
    }
}*/
.col-width(@cols){
    @result: ( ( @cols * ( @column-width + @gutter ) ) - @gutter );
}


.right(@maxScreen: @page-width){
    
    @media ( min-width: ( @maxScreen * ( @column-width + @gutter ) ) - @gutter ){
        float: right;
    }
}
.block(@maxScreen: @page-width){
    
    @media ( max-width: ( @maxScreen * ( @column-width + @gutter ) ) - @gutter ){
        width: auto;
    }
}
.span(@columns: @page-width, @cols: 1, @minScreen: @page-width) {
    
    float: left;
    
    //& when     ( @cols = 1) { margin: 0 0       @gutter 0 };
    margin-bottom: @gutter;
             
    & when(@minScreen > 0 ){ 
        @media ( min-width: ( @minScreen * ( @column-width + @gutter ) ) - @gutter ) {
            width: ( ( @columns * ( @column-width + @gutter ) ) - @gutter );
            //overflow: hidden;
            display: block;
            box-sizing: border-box;
            
            & when ( @cols > 1 ), ( @cols = 0 ) { margin-right: @gutter };
            & when ( @cols = -1) { margin-right: 0 };
            
            &:nth-child( @{cols}n ) when ( @cols > 1 ){
                margin-right: 0; 
            }
            &:not(:nth-child( @{cols}n )) when ( @cols > 1 ){
                margin-right: @gutter; 
            }
        }
    }
    
    & when (@minScreen = 0 ) {
        width: ( ( @columns * ( @column-width + @gutter ) ) - @gutter );
        //overflow: hidden;
        display: block;
        box-sizing: border-box;
        
        & when ( @cols > 1 ), ( @cols = 0 ) { margin-right: @gutter };
        & when ( @cols = -1) { margin-right: 0 };
        
        &:nth-child( @{cols}n ) when ( @cols > 1){
            margin-right: 0; 
        }
        &:not(:nth-child( @{cols}n )) when ( @cols > 1 ){
            margin-right: @gutter; 
        }
    }
    width: 100%;
    box-sizing: border-box;
}

.height(@columns, @minScreen: @page-width){
    
    & when ( @minScreen > 0 ){ 
        @media ( min-width: ( @minScreen * ( @column-width + @gutter ) ) - @gutter ) {
            height: ( @columns * ( @column-width + @gutter ) );
        }
    }
    
    & when ( @minScreen = 0 ){
        height: ( @columns * ( @column-width + @gutter ) );
    }
}

.width(@columns, @minScreen: @page-width){
    
    & when ( @minScreen > 0 ){ 
        @media ( min-width: ( @minScreen * ( @column-width + @gutter ) ) - @gutter ) {
            width: ( @columns * ( @column-width + @gutter ) );
        }
    }
    
    & when ( @minScreen = 0 ){
        width: ( @columns * ( @column-width + @gutter ) );
    }
}

@full-width-px: @page-width    * ( @column-width + @gutter ) - @gutter;
@sm-width-px:   @sm-page-cols  * ( @column-width + @gutter ) - @gutter;
@vsm-width-px:  @vsm-page-cols * ( @column-width + @gutter ) - @gutter;

@media-full-widthx: ~"(max-width: @{full-width-px})";
@media-sm-widthx:   ~"(max-width: @{sm-width-px})";
@media-vsm-widthx:  ~"(max-width: @{vsm-width-px})";

@media-full-width-min: ~"(min-width: @{full-width-px})";
@media-sm-width-Min:   ~"(max-width: @{sm-width-px})";
@media-vsm-width-Min:  ~"(max-width: @{vsm-width-px})";

/* Specific mixins */
.menu {
    list-style: none;
    li {
        display: block;
        &.hidden{
            display: none;
        }
        float: left;
        line-height: 20px;
        a{
            text-decoration: none;
            display: inline-block;
            line-height: 14px;
            margin-right: 2px;
            border-radius: 0;
            margin-top: 0;
            background-color: @green;
            color: white;
            padding: 8px;
        }
        a.active, a:focus, a:hover{
            background-color: @hover-green;
            outline: none;
        }
    }
}

.menu-block{
    .menu-button{
        display: none;
    }
    &.responsive{
        .menu-button{
            display: block;
        }
    }
}


.focus-outline{
    a:focus, a:hover{
        outline: @green 1px solid;
    }
    a:active{
        outline: none;
    }
}
.focus-underline{
    a:focus, a:hover{
        h1, h2, h3 {
            text-decoration: underline; 
        }
        outline: none;
   }
    a:active{
        outline: none;
    }
}
