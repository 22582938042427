/* 
    Created on : 04-Sep-2017, 11:07:28
    Author     : James Hamlin
    Copyright  : Royal Society of Biology (2017, 2020)
*/
@import (reference)"../less_inc/controls.less";
@import (reference)"../less_inc/typography.less";
@import (reference) "../less_inc/variables.less";
@import (reference) "../../biologist/less_inc/colour.less";


@import             "../less_inc/menu-responsive.less"; 

@import             "../less_inc/pageTop.less"; 
@import             "../less_inc/rsb_top_menu.less"; 
@import (less)      "../less_inc/rsb-left-menu.less";
@import (less)      "../less_inc/rsb-main-horiz-drop-down.less";


@import (less)      "../less_inc/homePage.less";
@import (less)      "../less_inc/Carousel.less";
@import (less)      "../less_inc/footer.less";

@import             "../less_inc/article.less";
@import             "../less_inc/search.less"; 
@import             "../less_inc/pagination.less";  

@import             "../less_inc/category.less"; 

@import (reference) "../../threeyearplan/less_inc/mixins.less";

/*@import "../less_inc/gopher.less";*/

/*@import (less) "../less_inc/oldcss/template16.css";
@import (less) "../less_inc/oldcss/template_extra.css";*/
//@import (less) "../less_inc/oldcss/page-top.css";
@import (less) "../less_inc/form.less";
/*@import (less) "../less_inc/oldcss/default-menu.css";*/
/*@import (less) "../less_inc/oldcss/home-menu.css";*/
/*@import (less) "../less_inc/oldcss/scheme.css";*/
/*@import (less) "../less_inc/oldcss/scheme-mysociety.css";*/
//@import (less) "../less_inc/oldcss/scheme-article.css";
/*@import (less) "../less_inc/oldcss/scheme-home.css";*/
/*@import (less) "../less_inc/oldcss/oer.css";*/ 
/*@import (less) "../less_inc/oldcss/carousel.css";*/
 


html, body{
    
    margin: 0;
    border: 0;
    padding: 0;
    overflow-x: hidden;
    display: block;
    
}

//html body{
//    padding: 0 10px;
//}

body{
    
    
    font-family: arial, san-serif;
    font-size: @baseFontSize;
    
    //background-color: red;
    margin: 0; 
    border: 0;
    padding: 0;
    
    display: block;
    
    #skip{
        position: absolute; top: -1000px;
    }
    
    #div-top-bar-wrapper, #div-pos-breadcrumb-wrapper, #main-row, #carousel, 
    footer.page-footer, .horiz-menu{
        
        &:after{
            content: "";
            display: table;
            clear: both;
        } 
        
        .centre(10,10); 
        .centre(13,13); 
        .centre(); 
        
        display: inline-block;
        
        //overflow-x: hidden;
    }
    
    #div-pos-breadcrumb-wrapper {
    
        position: relative;
        margin-top: -50px;
        
        color: black;
        padding: 0;
        border: 0;

        #div-pos-breadcrumb{
            display: block;
            margin: 20px 0;
        }

        *{
            display: inline;
        }

        h3:after{
            content: url('../images/system/arrow.png');
        }

        a, h3, span {
            color: black;
            font-size: 11px;
            font-weight: normal;
            padding: 0;
            display: inline;
        }
        ul {
            padding: 0;
        }
        ul, li, h3{
            background-color: transparent;
            display: inline;
        }
        span.divider {
            padding-right: .5em;
            padding-left: .5em;
        }
    }
    
    main{
        margin-top: 33px;
        @media ( max-width: .col-width( 13 )[@result] ){
            margin-top: 0;
        }
        &.wide{
            .span(13,2);
        }
        &.narrow{
            .span();
            .span(10,2,13);
            .span(10,2);
        }
    }
    
    #main-wrapper{
        
        .span(10, 1, 10);
        .span(13, 2, 13);
    }
    
    #pos-right-side{
         
        @media ( max-width: .col-width( 16 )[@result] ){
            display: none;
        }
        .span(3,2,16); 
        margin-top: -25px;
    }
    
       
   
}

@media ( min-width: .col-width( 13 )[@result] ) {
        
    body #div-top-bar-wrapper{
            height: 92px;
            //overflow-y: hidden;
    }
}