/* 
    Document   : menu.css
    Created on : Feb 28, 2013, 8:56:28 AM
    Author     : James Hamlin
    Description: The main style rules for the menus. Specific rules occure in the
                 files: default-menu.css and home-menu.css .
*/

@rsb-side-menu-indent: 15px;


#div-pos-left-menu{ 

    .span(6,1,6);
    .span(3,2,13);

    margin-bottom: 0;

    position: relative;
    float: left;

    ul.nav {
        margin:  0;
        padding: 0;

        ul{
            margin: 0;
            padding: 0;
        }
        line-height: 1.2;
        li{
            transition: max-height ease-in 2s;
            
            &.hidden{
                display: none;
            }

            list-style: none;
            li{
                display: block;
                
                &:not(.active){
                    li{
                        overflow-y: hidden;
                        /*height: 0;*/
                        max-height: 0;
                        
                    }
                    &:hover{
                        >ul>li{
                            max-height: 100em;
                            /*height: auto;*/
                        }
                    }
                }
                &[depth="0"]{
                    max-height: 100em;
                    /*height: auto;*/
                    
                }
                &[depth="1"]{
                    
                    a{
                        padding-top: 4px;
                        padding-bottom: 4px;
                        
                    }
                }
                
                &[depth="2"]{
                    line-height: 1.1;
                    font-size: 90%;
                    
                    a{
                        padding-top: 4px;
                        padding-bottom: 4px;
                        
                    }
                }
            }
            
            a{
                display: block;
                padding: 8px 0 8px @rsb-side-menu-indent;

                background-color: @grey;
                color: black;

                border-bottom: 1px solid @green;

                text-decoration: none;
                

                &.current[depth]{
                    background-color: @green;
                    color: white;
                    border-color: @white;
                }
                &[depth]:hover{
                    background-color: @light-green;
                    color: black;
                }
                &[depth="1"]{
            
                        background-color: @white;
                        margin-left: 1 * @rsb-side-menu-indent;
                
                }

                &[depth="2"]{
                 
                        background-color: @white;       
                        margin-left: 2 * @rsb-side-menu-indent;
                 
                }

                &[depth="3"]{
                    
                        background-color: @white;     
                        margin-left: 3 * @rsb-side-menu-indent;
                }

                &[depth="4"]{
                  
                        background-color: @white;     
                        margin-left: 4 * @rsb-side-menu-indent;
                }
            }

        }
    } 

    @media ( max-width: .col-width( 13 )[@result] ){


        &.menu-block{
            &.responsive{
                ul.nav{
                    display: block;
                }
            }
            ul.nav {
                background-color: @grey;
                
                box-shadow: 2px 2px 5px 5px @light-green;
               
                display: none;
                position: absolute;
                
                li a{
                    padding-right: 5px;
                }
                li a[depth]:not(:hover):not(.current){
                    background-color: @grey;
                    color: black;
                } 
                li:not(.active) li{
                    display: none;
                }
            }
        }
        //background-color: @green;
        //color: @white;

        .menu-button{
            &:after{
                content: ")";
            }
            &:before{
                content: "Menu (";
            }
        }

    }

    .menu-button{
        display: inline-block;
        background-color: @blueDark;

        color: @white;
        padding: 0 15px;
        line-height: 30px;
        margin: 0 0 2px 0;
        font-size: 13px;
        font-weight: bold;

        a{
            color: inherit;
            background-color: inherit;

            text-decoration: none;
        }

        &:hover{

            background-color: @green;
        }
    }
}

