#menu-block-top{
    position: relative;
    display: inline-block;
    float: right;
    
    margin-left: @gutter;
    
    .menu-button{
        display: none;

        background-color: @green;
        color: white;
        line-height: 14px;
        padding: 8px;
        border: none;
        float: right;
        &:focus, &:hover{
            background-color: @hover-green;
            outline: none;
        }
    }
    ul{
        float: right;
        margin: 0;
        padding: 0;
        .menu;
    }

    form{
        float: right;
    }
}

@media @media-sm-widthx{

    #menu-block-top{
        //position: relative;
        .menu-button{
            display: block;
        }
        ul{
            display: none;
        }
    }
     #menu-block-top.responsive{
        ul{
            position: absolute;
            top: 30px;
            right: 0px;
            display: block;
            background-color: @green;
            padding: @gutter;
            /*width: 15em;*/
            li{
                
                
                float: right;
                clear: both;
                border-bottom: 1px solid white;
                /*                    &:last-child{
                                        border-bottom: none;
                                    }*/
                a{
                    width: 10em;
                    text-decoration: none;
                }
            }
        }
    }
}
