/*
    Created on : 13 Jul 2020, 13:33:23
    Author     : james
*/


@import (less)      "HomePageMenu.less"; 

.home-section{
    
    display: block;
    
    .home-pos{
        
        display: block;
        float: left;
        margin: 20px;
        min-width: 200px;
        @supports (display: grid) {
            float: none;
            margin: 0;
            min-width: initial;
        }
    }
    
    @media ( min-width: .col-width(6)[@result] ){
            display: grid;
            
            grid-gap: @gutter;
            
            grid-template-columns: 
                auto
                auto;
    }
    @media ( min-width: .col-width(10)[@result] ){
        display: grid;
        grid-gap: @gutter;

        grid-template-columns: 
            .col-width(5)[@result]
            .col-width(5)[@result];
    }
    @media ( min-width: .col-width(13)[@result] ){
            
        grid-template-columns: 
            .col-width(5)[@result]
            .col-width(4)[@result]
            .col-width(4)[@result];
    }
    
    &.narrow{
        
        width: 100%;
        @media ( min-width: .col-width(10)[@result] ){
            width: .col-width(10)[@result];
            grid-template-columns: 
                .col-width(5)[@result]
                .col-width(5)[@result];
            
            
        }
        
        .home-pos{
            
            @media ( min-width: .col-width(6)[@result] ){
                &[row-count="1"]{
                    grid-column: span 2; 
                }
            }
        }
    }
    
    &.wide{
        .home-pos{
            @media ( min-width: .col-width(10)[@result] ){
            float: left;
            
//            @supports (display: grid){
                margin-bottom: 0;
//            }
        }
      
            @media ( min-width: .col-width(10)[@result] ){
                &[row-count="2"][col-num="1"]{
                     grid-column: span 2; 
                }
            }
        }
    }
    .home-pos {
        margin-bottom: 0;
        
        max-height: 233px;
        overflow: hidden;
        
        margin-bottom: @gutter;
        
        
     
        font-family: Arial, sans-serif;
        font-size: 13px;
        
        h1{
            font-size: 13px;
        }
        a{
            text-decoration: none;
            color: black;
            &:hover{
                opacity: 0.7;
            }
        }
        &#pos-row1-1{
            font-size: 16px;
            color: black;
            
            h1{
                font-size: 16px;
                
                & + p{
                    margin-top: 10px;
                }
            }
            
        }
        
        
        &.pos-grey .mod-inner, .moduletable .mod-inner.pos-grey {
            background-color: @grey;
        }
        &.pos-light-green .mod-inner, .moduletable .mod-inner.pos-light-green {
            background-color: @light-green;
        }
        &.pos-yellow .mod-inner, .moduletable  .mod-inner.pos-yellow {
            background-color: @dirty-yellow;
            h1{
                color: white;
            }
        }
        &.pos-purple .mod-inner, .moduletable .mod-inner.pos-purple {
            background-color: @purple;
        }
        &.pos-green .mod-inner, .moduletable .mod-inner.pos-green {
            background-color: @green;
        }
        &.pos-orange .mod-inner, .moduletable .mod-inner.pos-orange {
            background-color: @orange;
        }
        &.pos-policy-green .mod-inner, .moduletable .mod-inner.pos-policy-green {
            background-color: @policy-green;
        }
        
        &.tab-green .mod-title, .moduletable .mod-title.tab-green {
            background-color: @green;
            color: @white;
        }
        &.tab-blue .mod-title, .moduletable .mod-title.tab-blue {
            background-color: @blue;
            color: @white;
        }
        &.tab-aqua-blue .mod-title, .moduletable .mod-title.tab-aqua-blue {
            background-color: @aqua-blue;
        }
        &.tab-orange .mod-title, .moduletable .mod-title.tab-orange {
            background-color: @orange;
        }
        &.tab-policy-green .mod-title, .moduletable .mod-title.tab-policy-green {
            background-color: @policy-green;
        }
        
        
        .mod-inner:hover{
            opacity: 0.7;
        }
        .no-hover-opacity .mod-inner:hover{
            opacity: inherit;
        }
        
        /* --------- colours -------------- */
        
/*                           .module-style (@grey, @black);
        &.pos-warm-grey  { .module-style (@grey, @black); }
        &.pos-yellow     { .module-style (@dirty-yellow, @white); }
        &.pos-pale-green { .module-style (@light-green, @black); }*/
        
/*                           .tab-style(@green);
        &.tab-blue       { .tab-style(@blue); }
        &.tab-dark-green { .tab-style(@blueDark); }*/
        
/*        & > div:hover{
                
            opacity: 0.7;
        }*/
        
        li{
            list-style: none;
        }
        a.mod-articles-category-title{
            
            display: inline-block;
            text-decoration: none;
            font-weight: bold;
            color: @green;
            
            margin-bottom: 5px;
            
            &:hover{
                & > * {
                    color: white;
                    background-color: @green;
                }
            }
            
            h1, h2, h3, h4{
                font-size: inherit;
                color: inherit;
            }
        }
        
        & div.moduletable { /* module table */
            p{
                margin-top: 0;
            }

            
            
            &> h1, &> h2, &> h3, &> h4, &> h5{

                height: 30px;
                display: inline-block;
                
                line-height: 30px;
                margin: 0;
                padding: 0 15px;
                
                font-size: 13px;
                font-weight: bold;
                border-bottom: 3px solid white;
            }

            &:first-child > div:first-child {
                margin-top: 33px;
            }
            
            .mod-inner > div, .mod-inner > ul { /* custom or article anywhere etc */
                
                &.custom{
                    padding: 0;
                    ul{
                        margin: 0;
                    }
                    
                    li{
                        border-bottom: 3px solid white;
                        border-top: none;
                    }
                }
                .padding{
                    padding: 15px;
                    
                    img{
                        position: static;
                    }
                }
                
                
                position: relative;
                //top: 10px;
                z-index: 0;
                
                display: block;
                padding: 15px;
                
                height: 200px;
                &.small, &.customsmall{
                    height: 90px;
                }
                margin: 0;
                overflow: hidden;
                width: 100%;
                box-sizing: border-box;
                
                overflow: hidden;
                
                h1, h2, h3{
                    display: inline;
                }
                
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    
                    width: 100%;
                    height: auto;
                    z-index: -1;
                    //margin: -15px 0 0 -15px;
                }
                img.pull-right{
                    position: static;
                    width: auto;
                    float: right;
                    margin: -15px -15px 0 0;
                }
                
                img.pull-left{
                    position: static;
                    width: auto;
                    float: left;
                    margin: -15px 15px 0 -15px;
                }
            }

        }
        
        &[poscount="2"]{
            
            .moduletable { /* moduletable */
                .mod-inner div{ /* custom or article anywhere etc */
                
                    height: 90px;
                    margin-bottom: @gutter;
                }
                
                &:first-child  { /* first moduletable of 2 */
                    
                    &> div:first-child{
                        margin-top: 33px;

                        margin-bottom: 20px;
                    }
                }
            }
            
            
        }
        
        
        // form things
        
        fieldset{
            margin: 0;
            border: 0;
            padding: 0;
        }
        input[type='password'], input[type='text']{
            
            box-sizing: border-box;
            width: 100%;
            font-size: inherit;
            padding: 5px;
            border-color: white;
            border-width: 0px;
            margin: 0;
            height: auto;
            display: block;
            float: left;
            margin-bottom: 15px;
        }
        input[type='image']{
            border: 0;
            padding: 3px 0 0 0;
            margin-left: -20px;
            display: block;
            float: right;
            position: relative;
        }
    }
    /*max-height: 233px;*/
    
}

/*.module-style (@bg-col, @h-col){
    
    & > div{
        h1{
            color: @h-col;
        } 
        .mod-inner > div, .mod-inner > ul{  custom or article anywhere etc 
            background-color: @bg-col;
            
        }
    }
}
.tab-style(@tab-col,@fg-col: @white){
    & > div {
        &> h1, &> h2, &> h3, &> h4, &> h5 {
            background-color: @tab-col;
            color: @fg-col;
        }
    }
}*/

