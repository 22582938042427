/*
    Created on : 13 Jul 2020, 13:33:33
    Author     : james
*/


#carousel{


    display: inline-block;


    .inner-and-overlay{

        display: inline-block;



        position: relative;
        z-index: 0; /* so the menu drop-down is on top*/

        .carousel-inner{
            //overflow: hidden;
            display: block;
            /*opacity: .05;*/

            position: relative;

            width: 100%;

            background-color: white;

            .slide{
                width: 100%;
                height: auto;
                display: block;

                opacity: 0;
                z-index: 0;

                &:first-child{
                    position: relative;
                }

                position: absolute;
                top: 0;

                transition: opacity 2s ease;

                img{
                    width: 100%;
                    height: auto;
                }

                &.show{

                    opacity: 1;
                    z-index: 100;
                }
            }
        }

        .carousel-control{

            position: absolute;
            z-index: 100;
            top: 0;

            width: 100%;
            height: 100%;
            
            cursor: pointer;

            transition: opacity .8s ease-in-out;
            opacity: 0.1;
            &:hover{
                opacity: 1;
            }

            .wrapper{

                /*display: block;*/
                /*position: relative;*/
                /*height: 100%;*/

                /*& > div{*/
                    display: block;
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-0%, -50%);

                    & > div{
                        position: relative;
                        display: block;

                        width: 90%;
                        height: 36px;
                        margin: 0 auto;

                        a{

                            display: inline-block;
                            /*background-color: blue;*/
                            position: absolute;

                            img{
                                height: 100%;
                                width: auto;
                            }

                            &.right{
                                right: 0%;
                            }
                            &.left{
                                left: 0%;
                            }
                        }
                    }
                /*}*/
            }
        }

    }
    ol.carousel-indicators{

        display: block;
        text-align: center;
        list-style: disc;
        padding: 0; 
        margin: 4px 0 15px 0;
        line-height: 5px;
        

        li{
            display: inline-block;
            text-align: center;

            
            
            width: 5px;
            height: 5px;

            margin: 0 10px;

            a{
                background-color: @grey;
                
                border-radius: 50%;
                
                display: block;
                height: 100%;
                width: 100%;

            }
            &.show a{
                    background-color: @black;
            }
        }
    }
} 