.element-invisible{
    display: none;
}

#page-item{

    .item-image{
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .finder, .search{

        padding: 15px;
        display: block;
        font-size: 13px;
        
        background-color: @grey; 
        
        margin-top: 15px;

        .page-header{
            
            margin-bottom: 15px;
            
            h1, h2, h3, h4, h5, h6, h7{
                color: @black; 
                font-size: 21px;
                line-height: 24px;
                font-weight: normal;
                margin: 0;
            }
        }
        
        h1, h2, h3, h4, h5, h6, h7{

            font-size: inherit;
            line-height: 17px;
            font-weight: bold;
            padding: 0;
            margin: 0;
            text-decoration: none;
            color: @blue;
            clear: right;
        }

        h3{
            font-weight: normal;
        }
        
        a{
            .link-mixin;
        }
        
        ul, ol{
            
            margin-left: 65px;
            padding: 0;
            list-style: none;
            li{
                line-height: 20px;
            }
        }
        
        strong {
            font-weight: bold;
        }
    }
}